.partenaires {
    background-image: url("../../images/reunion-partenaires.jpg") !important;
}

.div-info table {
    margin: 33px;
    width: 1000px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

th img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

th img:hover {
    cursor: pointer;
}

@media only screen and (min-width:1400px) {

    th {
        padding: 2%;
        width: calc(100% / 3);
        min-width: 100px;
    }

}

@media only screen and (max-width:1400px) {

    tr {
        display: grid;
        max-width: 100vw !important;
    }

    .tr-mini {
        display: table !important;
    }

    th {
        padding: 0;
        margin: 14px;
        width: 90%;
        min-width: none;
        margin-left: auto;
        margin-right: auto;
    }

    .small {
        width: 60% !important;
        margin: 17px auto !important;
    }

}

@media only screen and (min-width: 500px) {

    .big-th {
        transform: scale(1.7);
    }

}

.big-width p img {
    transform: scale(1.1) translateY(17px);
    width: 100% !important;
}

.small-th {
    transform: scale(.81);
}